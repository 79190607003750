import React from "react";
import * as S from "./404.module.sass";
import "../sass/website.global.sass";
import Layout from "../components/Layout";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";

// markup
const IndexPage = () => {
  return (
    <Layout title={`404 Not Found`}>
      <div className={S.Layout404}>
        <div className={S.Logo}>
          <StaticImage
            src={"../images/lakeview-logo-white.svg"}
            alt={"logo"}
            placeholder={`none`}
          />
        </div>
        <div className={S.Text}>
          <h1>404</h1>
          <h2>Page not found</h2>
          <p>
            The page you are looking for doesn't exist or an other error
            occurred.
          </p>
          <p>
            <Link to={`/`}>Go back to Home Page</Link>.
          </p>
        </div>
      </div>
    </Layout>
  );
};

export default IndexPage;
